import { Navigate } from "react-router-dom";
import { useAuth } from "../../../providers/AuthProvider";
import { Role } from "../../../graphql/generated";

export type RoleGuardProps = {
  roles?: (string | null)[] | null;
  children: React.ReactNode;
};

const pathToCheckByRoles: Partial<Record<Role, string[]>> = {
  [Role.Hr]: ["/users", "/drivers"],
  [Role.Accounting]: [
    "/billing",
    "/settlements",
    "/reports",
    "/customers",
    "/business-locations",
    "/tractors",
    "/trailers",
    "/carriers",
    "/forecasting",
  ],
};

const HrPathToCheck = ["/users", "/drivers"];

const HRRoles = [Role.Hr];

const AccountingRoles = [Role.Accounting];

const isHROnly = (roles: Role[]) => {
  return roles.every((role) => HRRoles.includes(role));
};

const isAccountingOnly = (roles: Role[]) => {
  return roles.every((role) => AccountingRoles.includes(role));
};

const redirectOrLogout = (
  signOut: (uri?: string) => Promise<void>,
  routes?: string[]
) => {
  if (!routes?.length) {
    signOut();
    return null;
  }
  return <Navigate to={routes[0]} />;
};

const RoleGuard = (props: RoleGuardProps): JSX.Element | null => {
  const { roles, signOut } = useAuth();
  const { pathname } = window.location;

  if (
    !props.roles ||
    roles.some((role) => (props.roles || []).includes(role))
  ) {
    return <>{props.children}</>;
  }

  switch (true) {
    case isHROnly(roles as Role[]) &&
      HrPathToCheck.every((path) => !pathname.startsWith(path)):
      return redirectOrLogout(signOut, pathToCheckByRoles[Role.Hr]);

    case isAccountingOnly(roles as Role[]) &&
      pathToCheckByRoles[Role.Accounting]?.every(
        (path) => !pathname.startsWith(path)
      ):
      return redirectOrLogout(signOut, pathToCheckByRoles[Role.Accounting]);

    // no default
  }
  return null;
};

export const useRoles = () => {
  const { roles } = useAuth();
  return roles;
};

export default RoleGuard;
