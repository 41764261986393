import {
  CellClickEventArgs,
  DragAndDrop,
  EventClickArgs,
  Inject,
  NavigatingEventArgs,
  QuickInfoTemplatesModel,
  Resize,
  ResourceDetails,
  ResourceDirective,
  ResourcesDirective,
  ScheduleComponent,
  TimeScaleModel,
  TimelineMonth,
  TimelineViews,
  View,
  ViewDirective,
  ViewsDirective,
} from "@syncfusion/ej2-react-schedule";
import {
  LoadType,
  ShipmentLocationType,
  Status,
  TrailerType,
  TripAssetTypes,
} from "../../../graphql/generated";
import {
  AugmentedDriver,
  AugmentedShipment,
  PlanningToolProps,
} from "./PlanningTool";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  addDays,
  addMilliseconds,
  addMinutes,
  addSeconds,
  differenceInSeconds,
  endOfDay,
  isBefore,
  setDay,
  startOfDay,
} from "date-fns";
import { capitalize, isArray, keyBy, last, max, min, uniqBy } from "lodash";
import driverLabel from "../../../utils/labels/driverLabel";
import tractorLabel from "../../../utils/labels/tractorLabel";
import trailerLabel from "../../../utils/labels/trailerLabel";
import { shipmentLocationAddressLabel } from "../../../utils/labels/shipmentLocationLabel";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";
import {
  Alert,
  Box,
  Button,
  Card,
  ClickAwayListener,
  Fade,
  IconButton,
  Palette,
  PaletteColor,
  Popper,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import deliveryIcon from "../../../assets/icons/delivery.svg";
import ShipmentInfosContainer from "../../shipment/ShipmentInfos";
import { PersonRemove } from "@mui/icons-material";
import { shipmentStatusColors } from "../../shipment/ShipmentsList/ShipmentsList";
import TripViolations from "../../trip/TripDetails/TripViolations";
import {
  formatDateTime,
  formatDurationClock,
} from "../../../utils/labels/formatDateTime";
import LoadingOverlay from "../../common/LoadingOverlay";
import DriverLicenseExpiryWarning from "../../asset-management/DriverLicenseExpiryWarning/DriverLicenseExpiryWarning";
import { Feature, useFeature } from "../../account/Access/FeatureGuard";
import { DragEventArgs, EmitType, L10n } from "@syncfusion/ej2-base";

import { loadCldr } from "@syncfusion/ej2-base";
import { useTranslation } from "react-i18next";
import MaintenanceWarningsContainer from "../../maintenance/MaintenanceWarnings";
import QualificationWarningsContainer from "../../qualifications/QualificationWarnings";
import useDialog from "../../../utils/hooks/useDialog";
import TripSummary from "../../trip/TripSummary/TripSummary";
import TripRoute from "../TripPlanner/TripRoute";
import i18next from "i18next";
import TripSeparationModal from "../../../views/trip-planning/TripSeparationModal";
import { useConfirm } from "material-ui-confirm";

loadCldr(
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/main/fr/ca-gregorian.json"),
  require("cldr-data/main/fr/numbers.json"),
  require("cldr-data/main/fr/timeZoneNames.json")
);

L10n.load({
  en: {
    schedule: {
      today: "Today",
    },
    calendar: {
      today: "Today",
    },
  },
  fr: {
    schedule: {
      today: "Aujourd'hui",
    },
    calendar: {
      today: "Aujourd'hui",
    },
  },
});

export type PlanningCalendarProps = {
  drivers: PlanningToolProps["drivers"];
  tractors: PlanningToolProps["tractors"];
  trailers: PlanningToolProps["trailers"];
  driverShifts: PlanningToolProps["driverShifts"];
  maintenanceTasks: PlanningToolProps["maintenanceTasks"];
  onPeriodChange: PlanningToolProps["onPeriodChange"];
  shipments: PlanningToolProps["shipments"];
  filteredShipments: PlanningToolProps["filteredShipments"];
  onShipmentsChange: PlanningToolProps["onShipmentsChange"];
  onRefresh: PlanningToolProps["onRefresh"];
  driverIdBeingPlanned?: string | null;
  selectedShipmentId: string | null;
  parentHeight?: number;
  headerIndentTemplate?: () => JSX.Element;
  selectedDate?: Date;
  onShipmentsCombine: (shipments: AugmentedShipment[]) => void;
};

type DriverResource = {
  label: string;
  Id: string;
  color: string;
  subtitle: string;
  domicileId: string;
};

enum CalendarEventType {
  DRIVE_TO_PICKUP = "DRIVE_TO_PICKUP",
  DRIVE_TO_NEXT_TRIP = "DRIVE_TO_NEXT_TRIP",
  TRIP = "TRIP",
  PLACEHOLDER = "PLACEHOLDER",
}

type CalendarEvent = {
  Id: string;
  type: CalendarEventType;
  label: string;
  status: Status;
  pickupTime: string;
  dropoffTime: string;
  earliestPickup: Date;
  latestPickup: Date;
  description: string;
  driverId: string;
};

const PlanningCalendar = (props: PlanningCalendarProps) => {
  const { showDialog, hideDialog } = useDialog();
  const confirm = useConfirm();
  const { i18n, t } = useTranslation([
    "planning",
    "users",
    "orders",
    "common",
    "trips",
  ]);
  const { onShipmentsChange, shipments } = props;
  const scheduleObjRef = useRef<ScheduleComponent>(null);
  const initialSyncDone = useRef<boolean>(false);

  const [currentView, setCurrentView] = useState<View>("TimelineDay");
  const { isAvailable: isCommodityDriven } = useFeature(
    Feature.CommodityManagement
  );

  const [selectedEvent, setSelectedEvent] =
    useState<CalendarEvent | null>(null);
  const [selectedEventElement, setSelectedEventElement] =
    useState<HTMLElement | null>(null);
  const [tripSeparationModalOpen, setTripSeparationModalOpen] = useState(false);

  const driverResourceData: DriverResource[] = useMemo(
    () =>
      props.drivers.map((driver) => ({
        label: driverLabel(driver),
        Id: driver._id,
        color: "#610010",
        subtitle: `${tractorLabel(driver.associatedTractor)} / ${trailerLabel(
          driver.associatedTrailer
        )} ${driver.associatedAdditionalTrailers?.length ? " - " : ""} ${(
          driver.associatedAdditionalTrailers || []
        )
          .map(trailerLabel)
          .join(", ")}`,
        domicileId: driver.domicileId,
      })),
    [props.drivers]
  );

  const driversById = useMemo(
    () =>
      keyBy<AugmentedDriver | null>(props.drivers, (driver) =>
        driver ? driver._id : ""
      ),
    [props.drivers]
  );

  const shipmentEvents: CalendarEvent[] = useMemo(
    () =>
      uniqBy(
        props.shipments
          .filter((shipment) => shipment.trip?.driver)
          .map((shipment) => shipmentToEvents(shipment, isCommodityDriven))
          .flat(),
        (event) => {
          return event.type === CalendarEventType.DRIVE_TO_PICKUP ||
            event.type === CalendarEventType.DRIVE_TO_NEXT_TRIP
            ? `${event.driverId}${formatDateTime(
                event.pickupTime
              )}${formatDateTime(event.dropoffTime)}`
            : event.Id;
        }
      ),
    [props.shipments, isCommodityDriven]
  );

  const tryDropShipment = useCallback(
    (shipmentData: AugmentedShipment, targetCell: HTMLElement) => {
      if (!scheduleObjRef.current) {
        return;
      }

      if (targetCell.classList.contains("e-unavailable")) {
        return;
      }

      if (targetCell.classList.contains("e-drop-not-allowed")) {
        return;
      }
      if (targetCell.classList.contains("e-drop-would-conflict")) {
        return;
      }
      let cellData: CellClickEventArgs =
        scheduleObjRef.current.getCellDetails(targetCell);

      if (cellData?.groupIndex === undefined) {
        return;
      }

      let resourceDetails = scheduleObjRef.current.getResourcesByIndex(
        cellData.groupIndex
      ) as ResourceDetails | undefined;

      if (!resourceDetails) {
        return;
      }

      const selectedPickupTime = new Date(cellData.startTime);

      const shiftedDropoffTime = addSeconds(
        new Date(
          shipmentData.trip?.lastDropoffTime ||
            shipmentData.route?.lastDropoffTime
        ),
        differenceInSeconds(
          new Date(selectedPickupTime),
          new Date(
            shipmentData.trip?.firstPickupTime ||
              shipmentData.route?.firstPickupTime
          )
        )
      );

      const driver = props.drivers.find(
        (driver) =>
          resourceDetails && driver._id === resourceDetails.resourceData.Id
      );

      if (!driver) {
        return;
      }

      onShipmentsChange(
        shipments.map((shipment) =>
          shipment._id === shipmentData._id
            ? {
                ...shipmentData,
                tripId: shipmentData.tripId || shipment._id,
                trip: {
                  _id: shipmentData.trip?._id || shipment._id,
                  tripNumber: shipmentData.trip?.tripNumber || "NEW",
                  status: shipmentData.trip?.status || Status.Assigned,
                  polyline: shipmentData.trip?.polyline || "",
                  driver: driver._id,
                  firstPickupTime: selectedPickupTime.toISOString(),
                  lastDropoffTime: shiftedDropoffTime.toISOString(),
                  tractor: driver.associatedTractor?._id,
                  trailer: driver.associatedTrailer?._id,
                  additionalTrailers: driver.associatedAdditionalTrailers?.map(
                    (trailer) => trailer._id
                  ),
                  routeDistance:
                    shipmentData.trip?.routeDistance ||
                    shipmentData.route?.routeDistance ||
                    0,
                  shipmentLocations:
                    shipmentData.trip?.shipmentLocations ||
                    shipmentData.route?.locations ||
                    [],
                  distanceToStart: 0,
                  distanceToEnd: 0,
                },
                previousPickupTime: shipment.trip?.firstPickupTime,
              }
            : {
                ...shipment,
                previousPickupTime: shipment.trip?.firstPickupTime,
              }
        ),
        driver._id
      );

      return true;
    },
    [onShipmentsChange, props.drivers, shipments]
  );

  const onShipmentDrop = (event: React.DragEvent<HTMLDivElement>) => {
    const targetCell = event.target as HTMLElement;
    if (!targetCell) {
      return;
    }

    const shipmentDataString = event.dataTransfer.getData("text/plain");

    const shipmentData = JSON.parse(shipmentDataString) as AugmentedShipment;

    // Combine Shipments
    const eventElement = targetCell.closest(".e-appointment");
    if (eventElement) {
      const shipmentEvent =
        scheduleObjRef.current?.getEventDetails(eventElement);
      const shipment = props.shipments.find(
        (shipment) => shipment._id === shipmentEvent?.Id
      );
      if (!shipment) {
        return;
      }
      const allShipments = (shipment?.otherShipmentsInTrip || []).concat(
        shipment
      );
      showDialog({
        title: "Confirmation",
        description: "Do you want to combine these shipments?",
        type: "primary",
        actions: [
          {
            title: "Combine",
            type: "primary",
            onClick: () => {
              hideDialog();
              props.onShipmentsCombine(allShipments.concat(shipmentData));
            },
          },
          {
            title: "Cancel",
            type: "secondary",
            onClick: hideDialog,
          },
        ],
      });
      return;
    }
    if (!targetCell.classList.contains("e-work-cells")) {
      return;
    }

    tryDropShipment(shipmentData, targetCell);
  };

  const highlightWorkHours = useCallback(() => {
    const workCells = document.querySelectorAll(".e-work-cells");
    workCells.forEach((node) => {
      // check if node is a td element
      if (!(node instanceof HTMLElement)) {
        return;
      }
      if (!scheduleObjRef.current) {
        return;
      }
      const element = node;
      const cellStartDate = getCellStartTime(element);
      const cellEndDate = getCellEndTime(element);

      const groupIndex = Number(element.getAttribute("data-group-index"));
      const resourceDetails = scheduleObjRef.current.getResourcesByIndex(
        groupIndex
      ) as ResourceDetails | undefined;

      if (!resourceDetails) {
        return;
      }
      const driverId = resourceDetails.resourceData.Id;
      const domicileId = resourceDetails.resourceData.domicileId;

      const driver = props.drivers.find((driver) => driver._id === driverId);

      const shiftAppliesToDriver = (
        shift: PlanningToolProps["driverShifts"][0]
      ) => {
        return (
          (!shift.driverIds?.length &&
            (!shift.domicileId || shift.domicileId === domicileId)) ||
          shift.driverIds?.includes(driverId)
        );
      };

      const maintenanceTaskAppliesToDriver = (
        task: PlanningToolProps["maintenanceTasks"][0]
      ) => {
        if (task.assetType === TripAssetTypes.Driver) {
          return task.assetId === driverId;
        }
        if (task.assetType === TripAssetTypes.Tractor) {
          return driver?.associatedTractor?._id === task.assetId;
        }
        if (task.assetType === TripAssetTypes.Trailer) {
          return driver?.associatedTrailer?._id === task.assetId;
        }
      };

      const hasNoShift = props.driverShifts.every(
        (shift) => !shiftAppliesToDriver(shift)
      );

      const hasNoMaintanceTask = props.maintenanceTasks.every(
        (task) => !maintenanceTaskAppliesToDriver(task)
      );

      const fromDomicileTz = (date: Date, domicileTimeZone?: string) => {
        return zonedTimeToUtc(date, domicileTimeZone || "UTC");
      };

      const toDomicileTz = (date: Date, domicileTimeZone?: string) => {
        return utcToZonedTime(date, domicileTimeZone || "UTC");
      };

      const licenceHasExpiredInCell =
        new Date(driver?.license?.expiryDate) <= cellStartDate;

      const maintenanceTaskInCell = props.maintenanceTasks.some((task) => {
        const taskApplies = maintenanceTaskAppliesToDriver(task);
        if (!taskApplies) {
          return false;
        }
        if (task.startDate) {
          const taskStartTime = new Date(task.startDate);
          const taskEndTime = task.endDate ? new Date(task.endDate) : null;

          if (
            taskStartTime <= cellStartDate &&
            (!taskEndTime || taskEndTime >= cellEndDate)
          ) {
            return true;
          } else {
            return false;
          }
        } else if (task.startMileage) {
          const assetType = task.assetType;
          if (assetType === TripAssetTypes.Tractor) {
            return (
              task.startMileage <= (driver?.associatedTractor?.mileage || 0)
            );
          } else if (assetType === TripAssetTypes.Trailer) {
            return (
              task.startMileage <= (driver?.associatedTrailer?.mileage || 0)
            );
          }
        }

        return false;
      });

      const isWorkingInCell =
        !licenceHasExpiredInCell &&
        (hasNoShift ||
          props.driverShifts.some((shift) => {
            const shiftApplies = shiftAppliesToDriver(shift);

            if (!shiftApplies) {
              return false;
            }
            const domicileTz =
              shift.domicile?.addressTimezone ||
              driver?.domicileEntity?.addressTimezone ||
              "UTC";
            const cellStartDateAtDomicile = toDomicileTz(
              cellStartDate,
              domicileTz
            );
            const cellEndDateAtDomicile = toDomicileTz(cellEndDate, domicileTz);

            const shiftIncludesCell = shift.days.some((day) => {
              let shiftStartTime = new Date(cellStartDateAtDomicile);
              shiftStartTime = setDay(shiftStartTime, day);
              shiftStartTime.setHours(
                shift.startTime.hour || 0,
                shift.startTime.minute || 0
              );
              let shiftEndTime = addSeconds(
                new Date(cellEndDateAtDomicile),
                -1
              );
              shiftEndTime = setDay(shiftEndTime, day);
              shiftEndTime.setHours(
                shift.endTime.hour || 0,
                shift.endTime.minute || 0
              );

              if (isBefore(shiftEndTime, shiftStartTime)) {
                shiftEndTime = addDays(shiftEndTime, 1);
              }
              shiftStartTime = fromDomicileTz(shiftStartTime, domicileTz);
              shiftEndTime = fromDomicileTz(shiftEndTime, domicileTz);

              if (
                shiftStartTime <= cellStartDate &&
                shiftEndTime >= cellEndDate
              ) {
                return true;
              } else {
                return false;
              }
            });

            return shiftIncludesCell;
          })) &&
        (hasNoMaintanceTask || !maintenanceTaskInCell);

      if (isWorkingInCell) {
        element.classList.remove("e-unavailable");
      } else {
        element.classList.add("e-unavailable");
        if (licenceHasExpiredInCell) {
          element.classList.add("e-license-expired");
        }
      }
    });
  }, [
    props.driverShifts,
    props.maintenanceTasks,
    scheduleObjRef,
    props.drivers,
  ]);

  const highlightDropIndicators = useCallback(
    (shipmentId: string | null) => {
      const workCells = document.querySelectorAll(".e-work-cells");
      if (!shipmentId?.length) {
        workCells.forEach((node) => {
          node.classList.remove("e-drop-allowed");
          node.classList.remove("e-drop-not-allowed");
          node.classList.remove("e-drop-would-conflict");
        });
        const combinableEvents = document.querySelectorAll(".e-combinable");
        combinableEvents.forEach((node) => {
          node.classList.remove("e-combinable");
        });
      }
      const selectedShipment = props.shipments.find(
        (shipment) => shipment._id === shipmentId
      );
      if (!selectedShipment) {
        return;
      }
      const selectedShipmentEvent = shipmentToEvents(
        selectedShipment,
        isCommodityDriven
      ).find((event) => event.Id === shipmentId);

      if (!selectedShipmentEvent) {
        return;
      }

      if (!scheduleObjRef.current) {
        return;
      }
      const currentViewEvents = (
        scheduleObjRef.current.getCurrentViewEvents() as CalendarEvent[]
      ).filter((e) => e.type === CalendarEventType.TRIP);
      const combinableEvents = currentViewEvents.filter((event) => {
        const shipmentForEvent = props.shipments.find(
          (shipment) => shipment._id === event.Id
        );
        if (!shipmentForEvent) {
          return false;
        }
        if (shipmentForEvent.loadType === LoadType.FullTruckLoad) {
          return false;
        }
        if (selectedShipment.loadType === LoadType.FullTruckLoad) {
          return false;
        }
        const shipment1 = selectedShipment;
        const shipment2 = shipmentForEvent;
        const minTimeWindowDate1 = min(
          shipment1.shipmentLocations.flatMap((loc) =>
            loc.timeWindows.map((tw) => tw.fromDate)
          )
        );
        const maxTimeWindowDate1 = max(
          shipment1.shipmentLocations.flatMap((loc) =>
            loc.timeWindows.map((tw) => tw.toDate)
          )
        );
        const minTimeWindowDate2 = min(
          shipment2.shipmentLocations.flatMap((loc) =>
            loc.timeWindows.map((tw) => tw.fromDate)
          )
        );
        const maxTimeWindowDate2 = max(
          shipment2.shipmentLocations.flatMap((loc) =>
            loc.timeWindows.map((tw) => tw.toDate)
          )
        );

        return (
          (minTimeWindowDate1 <= maxTimeWindowDate2 &&
            minTimeWindowDate1 >= minTimeWindowDate2) ||
          (maxTimeWindowDate1 >= minTimeWindowDate2 &&
            maxTimeWindowDate1 <= maxTimeWindowDate2) ||
          (minTimeWindowDate2 <= maxTimeWindowDate1 &&
            minTimeWindowDate2 >= minTimeWindowDate1) ||
          (maxTimeWindowDate2 >= minTimeWindowDate1 &&
            maxTimeWindowDate2 <= maxTimeWindowDate1)
        );
      });

      combinableEvents.forEach((event) => {
        const element = document.querySelector(
          '[data-id="Appointment_' + event.Id + '"]'
        );
        if (!element) {
          return;
        }
        console.log(element);
        element.classList.add("e-combinable");
      });
      workCells.forEach((node) => {
        // check if node is a td element
        if (!(node instanceof HTMLElement)) {
          return;
        }
        if (!scheduleObjRef.current) {
          return;
        }
        const element = node;

        const groupIndex = getCellGroupIndex(element);
        const cellStartTime = getCellStartTime(element);
        const cellEndTime = getCellEndTime(element);
        const resourceDetails = scheduleObjRef.current.getResourcesByIndex(
          groupIndex
        ) as ResourceDetails | undefined;
        const resourceData =
          resourceDetails?.resourceData as DriverResource | null;
        const isSlotAvailable = !currentViewEvents.some(
          (event) =>
            event.Id !== shipmentId &&
            event.driverId === resourceData?.Id &&
            new Date(event.pickupTime) < cellEndTime &&
            new Date(event.dropoffTime) > cellStartTime
        );

        if (!isSlotAvailable) {
          element.classList.add("e-drop-not-allowed");
          return;
        }
        const cellStartDate = cellStartTime;

        if (!resourceDetails) {
          return;
        }
        const driverId = resourceDetails.resourceData.Id;

        const driver = props.drivers.find((driver) => driver._id === driverId);

        if (
          driver?.associatedTrailer?.type &&
          driver.associatedTrailer.type !== selectedShipment.trailerType
        ) {
          element.classList.add("e-drop-not-allowed");
          return;
        }

        const earliestPickup = new Date(selectedShipmentEvent.earliestPickup);
        const latestPickup = new Date(selectedShipmentEvent.latestPickup);
        if (earliestPickup <= cellStartDate && latestPickup >= cellStartDate) {
          element.classList.add("e-drop-allowed");
        } else {
          element.classList.add("e-drop-not-allowed");
        }
      });

      workCells.forEach((node) => {
        // check if node is a td element
        if (!(node instanceof HTMLElement)) {
          return;
        }
        if (!scheduleObjRef.current) {
          return;
        }
        const element = node;
        if (element.classList.contains("e-drop-not-allowed")) {
          return;
        }

        const projectedStartTime = getCellStartTime(element);

        const projectedEndTime = addSeconds(
          projectedStartTime,
          differenceInSeconds(
            new Date(selectedShipmentEvent.dropoffTime),
            new Date(selectedShipmentEvent.pickupTime)
          )
        );
        const groupIndex = getCellGroupIndex(element);
        const resourceDetails = scheduleObjRef.current.getResourcesByIndex(
          groupIndex
        ) as ResourceDetails | undefined;
        const resourceData =
          resourceDetails?.resourceData as DriverResource | null;

        const resourceWorkCells = Array.from(workCells).filter((cell) => {
          return getCellGroupIndex(cell) === groupIndex;
        });

        const projectedOccupiedCells = Array.from(resourceWorkCells).filter(
          (cell) => {
            const cellStartTime = getCellStartTime(cell);
            const cellEndTime = getCellEndTime(cell);
            if (
              (projectedStartTime <= cellStartTime &&
                cellStartTime <= projectedEndTime) ||
              (projectedStartTime < cellEndTime &&
                cellEndTime <= projectedEndTime)
            ) {
              return true;
            }

            return false;
          }
        );

        const projectedOccupiedCellsAreUnavailable =
          projectedOccupiedCells.some((projectedOccupiedCell) => {
            if (!scheduleObjRef.current) {
              return false;
            }
            if (projectedOccupiedCell.classList.contains("e-unavailable")) {
              return true;
            }
            const projectedOccupiedCellStartTime = getCellStartTime(
              projectedOccupiedCell
            );
            const projectedOccupiedCellEndTime = getCellEndTime(
              projectedOccupiedCell
            );
            return currentViewEvents.some(
              (event) =>
                event.Id !== shipmentId &&
                event.driverId === resourceData?.Id &&
                new Date(event.pickupTime) < projectedOccupiedCellEndTime &&
                new Date(event.dropoffTime) > projectedOccupiedCellStartTime
            );
          });

        if (projectedOccupiedCellsAreUnavailable) {
          element.classList.remove("e-drop-allowed");
          element.classList.add("e-drop-would-conflict");
          return;
        }
      });
    },
    [props.drivers, props.shipments, isCommodityDriven]
  );

  const sync = useCallback(() => {
    console.debug("Syncing scheduler state with react state");
    setTimeout(() => {
      if (scheduleObjRef.current) {
        const lastDate = last(scheduleObjRef.current.getCurrentViewDates());
        if (!lastDate) {
          return;
        }
        props.onPeriodChange({
          start: startOfDay(scheduleObjRef.current.getCurrentViewDates()[0]),
          end: endOfDay(lastDate),
        });
      }
      highlightWorkHours();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduleObjRef, highlightWorkHours]);

  useEffect(() => {
    setTimeout(() => {
      highlightWorkHours();
    }, 250);
  }, [props, highlightWorkHours]);

  useEffect(() => {
    if (shipmentEvents.length && !initialSyncDone.current) {
      sync();
      initialSyncDone.current = true;
    }
  }, [sync, initialSyncDone, shipmentEvents]);

  useEffect(() => {
    highlightWorkHours();
  }, [shipmentEvents, highlightWorkHours]);

  useEffect(
    function highlightDropIndicatorsOnSelectedShipmentChange() {
      highlightDropIndicators(props.selectedShipmentId);
    },
    [props.selectedShipmentId, highlightDropIndicators]
  );

  const resourceHeaderTemplate = useCallback(
    function ({
      resourceData: driverResource,
    }: {
      resourceData: DriverResource;
    }): JSX.Element {
      const driver = driversById[driverResource.Id];
      return (
        <Box
          sx={{
            position: "relative",
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "30px",
                height: "30px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img src={deliveryIcon} alt="delivery icon" />
              {driver ? <DriverLicenseExpiryWarning driver={driver} /> : null}
              {driver ? (
                <MaintenanceWarningsContainer
                  assetType={TripAssetTypes.Driver}
                  assetId={driver._id}
                />
              ) : null}
              {driver ? (
                <QualificationWarningsContainer
                  assetType={TripAssetTypes.Driver}
                  assetId={driver._id}
                />
              ) : null}
              {driver?.associatedTractor ? (
                <MaintenanceWarningsContainer
                  assetType={TripAssetTypes.Tractor}
                  assetId={driver.associatedTractor._id}
                />
              ) : null}
              {driver?.associatedTrailer ? (
                <MaintenanceWarningsContainer
                  assetType={TripAssetTypes.Trailer}
                  assetId={driver.associatedTrailer._id}
                />
              ) : null}
            </Box>
            <Stack
              direction="column"
              sx={{
                flex: 1,
                width: "calc(100%-30px)",
                marginLeft: "0 !important",
                paddingLeft: 1,
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  wordBreak: "break-word",
                  whiteSpace: "nowrap",
                }}
              >
                {driverResource.label}
              </Typography>
              <Typography variant="caption">
                {driverResource.subtitle}
              </Typography>
            </Stack>
          </Stack>
          <LoadingOverlay
            loading={props.driverIdBeingPlanned === driverResource.Id}
          />
        </Box>
      );
    },
    [driversById, props.driverIdBeingPlanned]
  );

  const quickInfoContentTemplate = useCallback(
    function (event: CalendarEvent): JSX.Element | null {
      const shipment = props.shipments.find(
        (shipment) => shipment._id === event.Id
      );
      if (!shipment) {
        return (
          <Box>
            <Typography variant="body2">{event.description}</Typography>
          </Box>
        );
      }
      return (
        <Box
          sx={{
            p: 2,
            backgroundColor: "primaryBackground.main",
          }}
        >
          {shipment.trip ? <TripViolations trip={shipment.trip} /> : null}
          {shipment.trip ? <CalendarTripSummary shipment={shipment} /> : null}
          {shipment.trip?.etaToNextTrip ? (
            <Alert severity="info">
              <Typography variant="caption">
                {t("etaToNextTrip")}{" "}
                {formatDurationClock(shipment.trip.etaToNextTrip)}
              </Typography>
            </Alert>
          ) : null}
          {shipment.trip?.status !== Status.Complete ? (
            <Tooltip title={t("removeDriverFromTrip")}>
              <IconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                }}
                onClick={() => {
                  showDialog({
                    title: t("removeDriverFromTrip", "Remove driver from trip"),
                    description: (
                      <>
                        <Typography>
                          {t(
                            "unassignConfirm",
                            "Do you really want to remove this driver from the trip? This action cannot be undone."
                          )}
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Typography>
                            {capitalize(t("users:driver.one", "Driver"))}:&nbsp;
                          </Typography>
                          <Typography fontWeight={"bold"}>
                            {driverResourceData.find(
                              (d) => d.Id === shipment.trip?.driver
                            )?.label || ""}
                          </Typography>
                        </Box>
                        <Box sx={{ display: "flex", flexDirection: "row" }}>
                          <Typography>
                            {t("orders:trip", "Trip")} #:&nbsp;
                          </Typography>
                          <Typography fontWeight={"bold"}>
                            {shipment?.trip?.tripNumber}
                          </Typography>
                        </Box>
                        <br />
                        {/* This is temporary for now, waiting for real reasons. */}
                        {/* <ReasonForm
                          title="Reason:"
                          reasons={[
                            {
                              label: "Driver unable to finish the trip",
                              value: "driver-unable-to-finish-trip",
                            },
                            {
                              label: "Request from mamagement",
                              value: "request-from-management",
                            },
                            {
                              label: "Other",
                              value: "other",
                            },
                          ]}
                          onChange={() => {}}
                        /> */}
                      </>
                    ),
                    actions: [
                      {
                        title: t("common:error.yesRemove", "Yes, remove"),
                        type: "error",
                        onClick: () => {
                          onShipmentsChange(
                            shipments.map((shipment) =>
                              shipment._id === event.Id && shipment.trip
                                ? {
                                    ...shipment,
                                    trip: {
                                      ...shipment.trip,
                                      driver: null,
                                      tractor: null,
                                      trailer: null,
                                      additionalTrailers: null,
                                    },
                                  }
                                : shipment
                            )
                          );
                          setSelectedEvent(null);
                          setSelectedEventElement(null);
                          hideDialog();
                        },
                      },
                      {
                        title: t("common:cancel", "Cancel"),
                        type: "primary",
                      },
                    ],
                  });
                }}
              >
                <PersonRemove />
              </IconButton>
            </Tooltip>
          ) : null}
          {shipment.otherShipmentsInTrip.length > 0 && (
            <Stack direction="row" justifyContent="center">
              <Button
                sx={{ alignSelf: "center" }}
                onClick={() => {
                  if (
                    [shipment, ...shipment.otherShipmentsInTrip].some(
                      (s) =>
                        s.status === Status.Complete ||
                        s.status === Status.InProgress
                    )
                  ) {
                    confirm({
                      title: t("common:error.title", "Error"),
                      description: t(
                        "trips:error.unseparatableTrips",
                        "Cannot separate a trip with completed or in progress shipments"
                      ),
                      hideCancelButton: true,
                      titleProps: { color: "error" },
                    });
                    return;
                  }
                  setTripSeparationModalOpen(true);
                }}
              >
                {t("trips:separateTrip", "Separate Trip")}
              </Button>
            </Stack>
          )}

          <TripSeparationModal
            open={tripSeparationModalOpen}
            onClose={() => setTripSeparationModalOpen(false)}
            shipments={[shipment, ...shipment.otherShipmentsInTrip]}
            onRefresh={props.onRefresh}
          />
        </Box>
      );
    },
    [
      onShipmentsChange,
      shipments,
      t,
      showDialog,
      hideDialog,
      driverResourceData,
      tripSeparationModalOpen,
      confirm,
      props,
    ]
  );

  const quickInfoTemplates = useMemo(
    () =>
      // @ts-ignore
      ({
        content: quickInfoContentTemplate,
        templateType: "Event",
        header: () => null,
        footer: () => null,
      } as QuickInfoTemplatesModel),
    [quickInfoContentTemplate]
  );

  const editorTemplate = useCallback(
    function (event: CalendarEvent): JSX.Element | null {
      const shipment = shipments.find((shipment) => shipment._id === event.Id);
      if (!shipment) {
        return null;
      }

      return shipment.trip ? (
        <CalendarTripDetails
          shipment={shipment}
          // onRefresh={(shipmentIds) => {
          //   scheduleObjRef.current?.closeEditor();
          //   return onRefresh(shipmentIds);
          // }}
        />
      ) : null;
    },
    [shipments]
  );

  const eventSettings = useMemo(
    () => ({
      dataSource: shipmentEvents
        // We need placeholders for drivers with no shipments
        // otherwise they won't display in the resources
        .concat(
          driverResourceData.map((driverResource) => ({
            description: "",
            driverId: driverResource.Id,
            Id: `driver-${driverResource.Id}-placeholder-event`,
            label: "",
            status: Status.Assigned,
            pickupTime: new Date(0).toISOString(),
            dropoffTime: new Date(0).toISOString(),
            earliestPickup: new Date(0),
            latestPickup: new Date(0),
            type: CalendarEventType.PLACEHOLDER,
          }))
        ),
      fields: {
        subject: { title: "Order #", name: "label" },
        startTime: { title: "Pickup time", name: "pickupTime" },
        endTime: { title: "Dropoff time", name: "dropoffTime" },
        description: { title: "Description", name: "description" },
      },
    }),
    [shipmentEvents, driverResourceData]
  );

  const group = useMemo(
    () => ({
      enableCompactView: false,
      resources: ["Consultants"],
    }),
    []
  );

  const timeScale = useMemo(
    () => getTimeScaleForView(currentView),
    [currentView]
  );

  const theme = useTheme();

  const cellDoubleClick: EmitType<CellClickEventArgs> = useCallback((event) => {
    event.cancel = true;
  }, []);

  const cellClick: EmitType<CellClickEventArgs> = useCallback((event) => {
    event.cancel = true;
  }, []);

  const onEventRendered = useCallback(
    (args: { data: CalendarEvent; element: HTMLElement }) => {
      const event = args.data;
      if (event.type === CalendarEventType.TRIP) {
        const shipment = props.shipments.find(
          (shipment) => shipment._id === args.data.Id
        );
        const hasViolations = !!shipment?.trip?.violations?.length;
        args.element.style.backgroundColor = hasViolations
          ? theme.palette.warning.main
          : (
              theme.palette[
                shipmentStatusColors[args.data.status] as keyof Palette
              ] as PaletteColor
            )?.main;
      } else if (event.type === CalendarEventType.DRIVE_TO_PICKUP) {
        args.element.style.backgroundColor = "#3f51b5";
      } else if (event.type === CalendarEventType.DRIVE_TO_NEXT_TRIP) {
        args.element.style.backgroundColor = "#6c3fb5";
      }
    },
    [props.shipments, theme.palette]
  );

  const navigating: EmitType<NavigatingEventArgs> = useCallback(
    (event) => {
      if (event.action === "view") {
        setCurrentView(event.currentView);
        if (scheduleObjRef.current) {
          scheduleObjRef.current.timeScale = getTimeScaleForView(
            event.currentView
          );
        }
      }
      sync();
    },
    [sync]
  );

  const drag: EmitType<DragEventArgs> = useCallback(
    (payload: { data: CalendarEvent }) => {
      highlightDropIndicators(payload.data.Id);
    },
    [highlightDropIndicators]
  );

  const dragStop: EmitType<DragEventArgs> = useCallback(
    (payload: { cancel: boolean; data: CalendarEvent; event: Event }) => {
      const { data } = payload;
      if (data.status === Status.Complete) {
        payload.cancel = true;
      }
      const target = payload.event.target as HTMLElement;
      if (!target) {
        return;
      }
      const shipmentData = props.shipments.find(
        (shipment) => shipment._id === data.Id
      );
      if (!shipmentData) {
        return;
      }
      const isAccepted = tryDropShipment(shipmentData, target);
      if (!isAccepted) {
        payload.cancel = true;
      }
      highlightDropIndicators(null);
    },
    [props.shipments, tryDropShipment, highlightDropIndicators]
  );

  useEffect(() => {
    if (scheduleObjRef.current && props.parentHeight) {
      scheduleObjRef.current.refresh();
    }
  }, [props.parentHeight]);

  if (!shipments.length) {
    return null;
  }

  return (
    <div
      onDragOver={(event) => {
        event.preventDefault();
      }}
      onDrop={onShipmentDrop}
      style={{
        height: "100%",
      }}
    >
      <ScheduleComponent
        ref={scheduleObjRef}
        cssClass="schedule-drag-drop"
        selectedDate={props.selectedDate}
        width="100%"
        height="calc(100% - 75px)"
        locale={i18n.language}
        allowInline={false}
        currentView={currentView}
        resourceHeaderTemplate={resourceHeaderTemplate}
        quickInfoTemplates={quickInfoTemplates}
        editorTemplate={editorTemplate}
        headerIndentTemplate={props.headerIndentTemplate}
        eventSettings={eventSettings}
        group={group}
        //   actionBegin={onActionBegin.bind(this)}
        timeScale={timeScale}
        rowAutoHeight
        allowDragAndDrop={true}
        allowResizing={false}
        cellDoubleClick={cellDoubleClick}
        cellClick={cellClick}
        eventRendered={onEventRendered}
        navigating={navigating}
        drag={drag}
        dragStop={dragStop}
        workHours={workHours}
        workDays={workDays}
        showQuickInfo={false}
        eventClick={(args: EventClickArgs) => {
          setSelectedEvent(args.event as CalendarEvent);
          setSelectedEventElement(
            isArray(args.element) ? args.element[0] : args.element
          );
        }}
      >
        <ResourcesDirective>
          <ResourceDirective
            field="driverId"
            title="Consultant"
            name="Consultants"
            allowMultiple={false}
            dataSource={driverResourceData}
            textField="label"
            idField="Id"
            colorField="color"
          ></ResourceDirective>
        </ResourcesDirective>
        <ViewsDirective>
          <ViewDirective option="TimelineDay" displayName={t("timelineDay")} />
          <ViewDirective
            option="TimelineWeek"
            displayName={t("timelineWeek")}
          />
          <ViewDirective
            option="TimelineMonth"
            displayName={t("timelineMonth")}
          />
        </ViewsDirective>
        <Inject
          services={[TimelineViews, TimelineMonth, Resize, DragAndDrop]}
        />
      </ScheduleComponent>
      {selectedEvent ? (
        <ClickAwayListener
          onClickAway={(_event) => {
            setSelectedEvent(null);
            setSelectedEventElement(null);
          }}
          mouseEvent="onMouseDown"
        >
          <Popper
            open={!!selectedEvent && !!selectedEventElement}
            anchorEl={selectedEventElement}
            transition
            placement="right"
          >
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <Card>
                  {quickInfoContentTemplate(selectedEvent) || <div></div>}
                </Card>
              </Fade>
            )}
          </Popper>
        </ClickAwayListener>
      ) : null}
    </div>
  );
};

export default PlanningCalendar;

const getTimeScaleForView = (view: View): TimeScaleModel => {
  return view === "TimelineWeek"
    ? {
        enable: true,
        interval: 24 * 60,
        slotCount: 8,
      }
    : view === "TimelineDay" || view === "Day"
    ? {
        enable: true,
        interval: 4 * 60,
        slotCount: 8,
      }
    : {
        enable: true,
        interval: 24 * 60,
        slotCount: 1,
      };
};

const shipmentToEvents = (
  shipment: AugmentedShipment,
  isCommodityDriven: boolean
): CalendarEvent[] => {
  const receiverLocation = shipment.shipmentLocations.find(
    (location) =>
      location.locationType === ShipmentLocationType.DropOff ||
      location.locationType === ShipmentLocationType.DropForHook
  );
  const shippedGoods = shipment.shipmentLocations.flatMap(
    (location) => location.shippedGoods
  );
  const firstShippedGood = shippedGoods[0];

  const tripEvent: CalendarEvent = {
    Id: shipment._id,
    type: CalendarEventType.TRIP,
    label: isCommodityDriven
      ? `#${shipment.shipmentNumber} ${shipment.otherShipmentsInTrip
          .map((s) => `#${s.shipmentNumber}`)
          .join(", ")} ${receiverLocation?.receiver?.code} / ${
          firstShippedGood?.label
        }`
      : `#${shipment.shipmentNumber} - ${shipment.originState} / ${shipment.destinationState} - ${shipment.customer.name}`,
    status: shipment.trip?.status || shipment.status,
    pickupTime:
      shipment.trip?.firstPickupTime || shipment.route?.firstPickupTime, // "2023-05-19T03:30:00.000Z",
    dropoffTime:
      shipment.trip?.lastDropoffTime || shipment.route?.lastDropoffTime, // "2023-05-19T04:30:00.000Z",
    earliestPickup: shipment.earliestPickup,
    latestPickup: shipment.latestPickup,
    description: `${shipmentLocationAddressLabel(
      shipment.route?.locations?.[0]
    )} to ${shipmentLocationAddressLabel(last(shipment.route?.locations))}`,
    driverId: shipment.trip?.driver as string,
  };

  const driveToPickupPickupTime = shipment.trip?.etaFromPreviousTrip
    ? addSeconds(
        new Date(shipment.trip.firstPickupTime),
        -shipment.trip.etaFromPreviousTrip
      )
    : null;

  const driveToNextTripTime = shipment.trip?.etaToNextTrip
    ? addSeconds(
        new Date(shipment.trip.lastDropoffTime),
        shipment.trip.etaToNextTrip
      )
    : null;

  const driveToNextTripEvent: CalendarEvent | null =
    shipment.trip?.etaToNextTrip && driveToNextTripTime
      ? {
          Id: `${shipment._id}-drive-to-next-trip`,
          type: CalendarEventType.DRIVE_TO_NEXT_TRIP,
          label: `${formatDurationClock(shipment.trip.etaToNextTrip)} ⛟`,
          status: shipment.trip.status,
          pickupTime: shipment.trip?.lastDropoffTime,
          dropoffTime: driveToNextTripTime.toISOString(),
          earliestPickup: shipment.trip?.lastDropoffTime,
          latestPickup: driveToNextTripTime,
          description: i18next.t(
            "driveToNextTrip",
            "Drive to next trip from {{origin}}",
            {
              origin: `${shipment.destinationCity}, ${shipment.destinationState}`,
            }
          ),
          driverId: shipment.trip?.driver as string,
        }
      : null;

  const driveToPickupEvent: CalendarEvent | null =
    shipment.trip?.etaFromPreviousTrip && driveToPickupPickupTime
      ? {
          Id: `${shipment._id}-drive-to-pickup`,
          type: CalendarEventType.DRIVE_TO_PICKUP,
          label: `${formatDurationClock(shipment.trip.etaFromPreviousTrip)} ⛟`,
          status: shipment.trip.status,
          pickupTime: driveToPickupPickupTime.toISOString(),
          dropoffTime: shipment.trip?.firstPickupTime,
          earliestPickup: driveToPickupPickupTime,
          latestPickup: driveToPickupPickupTime,
          description: i18next.t(
            "driveFromPreviousTrip",
            "Drive from previous trip to {{destination}}",
            {
              destination: `${shipment.originCity}, ${shipment.originState}`,
            }
          ),
          driverId: shipment.trip?.driver as string,
        }
      : null;

  const events = [
    ...(driveToPickupEvent ? [driveToPickupEvent] : []),
    ...(driveToNextTripEvent ? [driveToNextTripEvent] : []),
    tripEvent,
  ];

  return events;
};

type AugmentedShipmentWithTrip = Omit<AugmentedShipment, "trip"> & {
  trip: NonNullable<AugmentedShipment["trip"]>;
};

export const AugmentedShipmentDetails = ({
  shipment,
  onRefresh,
}: {
  shipment: AugmentedShipment;
  onRefresh: PlanningToolProps["onRefresh"];
}) => {
  const [selectedShipment, setSelectedShipment] =
    useState<AugmentedShipment>(shipment);

  return (
    <Stack direction="row">
      {shipment.otherShipmentsInTrip.length ? (
        <Tabs
          orientation="vertical"
          value={selectedShipment}
          onChange={(_, value) => {
            setSelectedShipment(value);
          }}
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          {[shipment, ...shipment.otherShipmentsInTrip].map((shipment) => (
            <Tab label={`#${shipment.shipmentNumber}`} value={shipment} />
          ))}
        </Tabs>
      ) : null}
      <Stack sx={{ display: "flex", flex: 1, flexDirection: "column" }}>
        <ShipmentInfosContainer
          shipmentId={selectedShipment._id}
          onLoad={() => {}}
          actionsInNewTab={true}
          onDeleted={(shipmentId) => {
            onRefresh([shipmentId]);
          }}
          canAssign={false}
        />
      </Stack>
    </Stack>
  );
};

const CalendarTripDetails = ({ shipment }: { shipment: AugmentedShipment }) => {
  if (!shipment.trip) {
    return null;
  }
  const tripSummaryData = tripSummaryDataForAugmentedShipment(
    shipment as AugmentedShipmentWithTrip
  );

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      flex={1}
      spacing={3}
    >
      <Box
        sx={{
          flex: 1,
        }}
      >
        <CalendarTripSummary shipment={shipment} />
      </Box>
      <Box
        sx={{
          pb: 2,
          height: 300,
          flex: 2,
        }}
      >
        <TripRoute trip={tripSummaryData} />
      </Box>
    </Stack>
  );
};

const CalendarTripSummary = ({ shipment }: { shipment: AugmentedShipment }) => {
  if (!shipment.trip) {
    return null;
  }
  const tripSummaryData = tripSummaryDataForAugmentedShipment(
    shipment as AugmentedShipmentWithTrip
  );

  return <TripSummary trip={tripSummaryData} readonly />;
};

const tripSummaryDataForAugmentedShipment = (
  shipment: AugmentedShipmentWithTrip
) => {
  return {
    ...shipment.trip,
    shipments: [shipment, ...shipment.otherShipmentsInTrip],
    driver: shipment.trip?.driver
      ? {
          _id: shipment.trip.driver,
          firstname: "",
          lastname: "",
        }
      : null,
    tractor: shipment.trip?.tractor
      ? {
          _id: shipment.trip.tractor,
          serialNumber: "",
        }
      : null,
    trailer: shipment.trip?.trailer
      ? {
          _id: shipment.trip.trailer,
          serialNumber: "",
          type: TrailerType.Tanker,
        }
      : null,
    carrier: shipment.trip?.carrier
      ? {
          _id: shipment.trip.carrier,
          name: "",
        }
      : null,
  };
};

const workHours = {
  start: "00:00",
  end: "23:59",
  highlight: true,
};

const workDays = [0, 1, 2, 3, 4, 5, 6];

const getCellGroupIndex = (element: Element) =>
  parseInt(element.getAttribute("data-group-index") || "-1", 10);

const getCellStartTime = (element: Element) =>
  new Date(parseFloat(element.getAttribute("data-date") || "0"));

const getCellEndTime = (element: Element) => {
  if (element.nextSibling) {
    return getCellStartTime(element.nextSibling as Element);
  }

  if (element.previousSibling) {
    const previousSiblingStartTime = getCellStartTime(
      element.previousSibling as Element
    );
    const elementStartTime = getCellStartTime(element);
    const duration =
      elementStartTime.getTime() - previousSiblingStartTime.getTime();

    return addMilliseconds(elementStartTime, duration);
  }

  return addMinutes(getCellStartTime(element), 30);
};
